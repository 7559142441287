<template>
  <div
    class="login-form"
    @keypress.enter="submit"
  >
    <div
      v-if="submitError"
      class="login-form__error"
    >
      почта или пароль введены неверно
    </div>

    <form-email-field
      ref="emailField"
      v-model="email"
      :submit-error="submitError ? [{ invalidLogin: true }] : []"
      class="login-form__email"
    />

    <form-password-field
      ref="passwordField"
      v-model="password"
      :submit-error="submitError ? [{ invalidPassword: true }] : []"
      class="login-form__password"
    />

    <div class="m-btn__pull">
      <button
        id="button_sign_in"
        class="login-form__submit m-btn"
        type="submit"
        :disabled="sending"
        @click="submit"
      >
        Войти
      </button>
    </div>

    <button
      id="link_to_renew_the_password"
      class="login-form__remember m-btn"
      @click="$emit('remember')"
    >
      Я забыл пароль
    </button>
  </div>
</template>

<script>
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPasswordField from '@/components/v2/common/fileds/FormPasswordField.vue';

export default {
  name: 'LoginForm',
  components: { FormPasswordField, FormEmailField },

  data() {
    return {
      email: this.$store.getters.loginEmail,
      password: '',
      submitError: false,
      sending: false,
    };
  },

  methods: {
    validateForm() {
      let valid = true;

      if (!this.$refs.emailField.validate()) {
        valid = false;
      }

      if (!this.$refs.passwordField.validate()) {
        valid = false;
      }

      return valid;
    },

    submit() {
      this.submitError = false;

      if (!this.validateForm()) {
        return false;
      }

      this.sending = true;

      this.$api.login(this.email, this.password)
        .then((response) => {
          if (!response.role) {
            this.submitError = true;
            this.$emit('error');
          } else {
            this.$emit('login', response);
          }
        })
        .catch(() => {
          this.$emit('error');
        })
        .finally(() => {
          this.sending = false;
        });

      return true;
    },
  },
};

</script>

<style scoped lang="scss">

.login-form {
  position: relative;
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  @include media-down(tablet) {
    padding-top: 32px;
  }

  .login-form__error {
    position: absolute;
    top: 26px;
    left: 0;
    color: #FF0040;
    font-size: 14px;
    line-height: 100%;
  }

  .login-form__email {
    margin-bottom: 10px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }

  .login-form__password {
    margin-bottom: 20px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }

  .m-btn__pull {
    border-radius: 15px;
    margin-bottom: 20px;

    @include media-down(tablet) {
      margin-bottom: 12px;
    }
  }

  .login-form__remember {
    margin-left: auto;
    margin-right: auto;
    color: #73B0F4;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    position: relative;

    @include media-down(tablet) {
      font-weight: 500;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      transition: 200ms;
      height: 2px;
      width: 0;
      background-color: #73B0F4;
    }

    &:hover:after {
      width: 100%;
    }
  }
}

</style>

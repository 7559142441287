var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-form"},[_c('div',{class:['auth-form-container', {
      error: _vm.error,
      'auth-form__remember-success': _vm.form === 'remember-success',
    }]},[_c('button',{staticClass:"auth-form__close m-btn",attrs:{"id":"closing_cross"},on:{"click":_vm.closeAuthModal}},[_c('img',{attrs:{"src":"/v2/auth/modal-close.svg","alt":"Закрыть"}})]),_vm._v(" "),(_vm.form !== 'remember-success')?_c('div',{staticClass:"tabs"},[_c('button',{class:['m-btn', { active: _vm.form === 'register' }],attrs:{"id":"list_registration"},on:{"click":function($event){_vm.form='register'}}},[_c('span',[_vm._v("Регистрация")])]),_vm._v(" "),_c('button',{class:['m-btn', { active: _vm.form === 'login' || _vm.form === 'remember' }],attrs:{"id":"list_authorization"},on:{"click":function($event){_vm.form='login'}}},[_c('span',[_vm._v("Войти")])])]):_vm._e(),_vm._v(" "),(_vm.form === 'register')?_c('register-form',{on:{"register":_vm.register,"error":_vm.shaking}}):_vm._e(),_vm._v(" "),(_vm.form === 'login')?_c('login-form',{on:{"login":_vm.login,"remember":function($event){_vm.form = 'remember'},"error":_vm.shaking}}):_vm._e(),_vm._v(" "),(_vm.form === 'remember')?_c('remember-password-form',{on:{"success":_vm.submitRemember,"error":_vm.shaking}}):_vm._e(),_vm._v(" "),(_vm.form === 'remember-success')?_c('remember-success'):_vm._e(),_vm._v(" "),(_vm.form !== 'register')?_c('div',{staticClass:"auth-form__soc"},[_c('span',[_vm._v("Возникли трудности? Пишите нам в чат:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2)]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"auth-form__overlay",on:{"click":_vm.closeAuthModal}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"auth-form__whatsapp",attrs:{"href":"https://wa.me/79686286327","target":"_blank"}},[_c('img',{attrs:{"src":"/v2/main/form/whatsapp.svg","alt":"whatsapp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"auth-form__telegram",attrs:{"href":"https://t.me/schoolsynergy_bot","target":"_blank"}},[_c('img',{attrs:{"src":"/v2/main/form/tg.svg","alt":"telegram"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"auth-form__mail",attrs:{"href":"mailto:nstolbova@synergy.ru","target":"_blank"}},[_c('img',{attrs:{"src":"/v2/main/form/mail.svg","alt":"email"}})])
}]

export { render, staticRenderFns }
<template>
  <div
    v-if="!acceptCookiePrivacy"
    class="cookies_popup"
  >
    <div class="cookies_popup_wr">
      <img
        src="/img/cookies.svg"
        alt="cookies"
      >
      <div class="cookies_popup_content">
        <p>
          Наш сайт использует файлы<br class="showmobile"> cookies.
          Узнать больше о файлах<br class="showmobile"> cookie можете в нашей
          <nuxt-link to="/privacy/">
            Политике конфиденциальности
          </nuxt-link>
        </p>
        <button
          @click="close"
          v-text="`Принять`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiesPopup',

  computed: {
    acceptCookiePrivacy() {
      return this.$store.getters.acceptCookiePrivacy;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  mounted() {
    if (!localStorage.getItem('user_saw_popup')) {
      setTimeout(() => {
        this.$store.commit('acceptCookiePrivacy', false);
      }, 1000);
    }
  },

  methods: {
    close() {
      localStorage.setItem('user_saw_popup', 'accept');
      this.$store.commit('acceptCookiePrivacy', true);
    },
  },
};
</script>

<style scoped lang="scss">
.cookies_popup {
  width: 100%;
  position: fixed;
  z-index: 98;
  bottom: 0;
  right: 0;
  background: rgb(255 255 255);
  box-shadow: 0 -4px 32px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;

  &_wr {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    @include respond-to(allmobile) {
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 96%;
      margin: 1em auto;
      padding: 0 1.5em;
    }

    img {
      margin: 0 2em 0 0;
      padding: 1em 0;

      @include respond-to(allmobile) {
        // display: none;
        margin: 0;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: $grey;
      width: 650px;
      margin: 1em 2em 1em 0;

      @include respond-to(allmobile) {
        max-width: 100%;
        width: 100%;
        margin: 0 0 1em 0;
      }
    }

    button {
      font-family: "Gilroy", sans-serif;
      font-size: 16px;
      padding: 1em 2em;
      border-radius: 16px;
      outline: none;
      border: none;
      background: $orange-color;
      color: $white-color;
      cursor: pointer;
      transition: all 0.5s ease;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-weight: 400;
      text-align: center;
      z-index: 2;
      min-width: 127px;
      width: auto;

      &:hover {
        background: lighten($orange-color, 10%);
      }

      @include respond-to(allmobile) {
        margin: 0;
      }
    }
  }

  &_content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    a {
      color: $orange-color;
    }

    @include respond-to(allmobile) {
      width: 75%;
    }
  }
}

.showmobile {
  display: none;
  @include respond-to(allmobile) {
    display: block;
  }
}
</style>

<template>
  <div class="auth-form">
    <div
      :class="['auth-form-container', {
        error,
        'auth-form__remember-success': form === 'remember-success',
      }]"
    >
      <button
        id="closing_cross"
        class="auth-form__close m-btn"
        @click="closeAuthModal"
      >
        <img
          src="/v2/auth/modal-close.svg"
          alt="Закрыть"
        >
      </button>

      <div
        v-if="form !== 'remember-success'"
        class="tabs"
      >
        <button
          id="list_registration"
          :class="['m-btn', { active: form === 'register' }]"
          @click="form='register'"
        >
          <span>Регистрация</span>
        </button>
        <button
          id="list_authorization"
          :class="['m-btn', { active: form === 'login' || form === 'remember' }]"
          @click="form='login'"
        >
          <span>Войти</span>
        </button>
      </div>

      <register-form
        v-if="form === 'register'"
        @register="register"
        @error="shaking"
      />
      <login-form
        v-if="form === 'login'"
        @login="login"
        @remember="form = 'remember'"
        @error="shaking"
      />
      <remember-password-form
        v-if="form === 'remember'"
        @success="submitRemember"
        @error="shaking"
      />
      <remember-success
        v-if="form === 'remember-success'"
      />

      <div
        v-if="form !== 'register'"
        class="auth-form__soc"
      >
        <span>Возникли трудности? Пишите нам в чат:</span>
        <a
          href="https://wa.me/79686286327"
          target="_blank"
          class="auth-form__whatsapp"
        >
          <img
            src="/v2/main/form/whatsapp.svg"
            alt="whatsapp"
          >
        </a>
        <a
          href="https://t.me/schoolsynergy_bot"
          target="_blank"
          class="auth-form__telegram"
        >
          <img
            src="/v2/main/form/tg.svg"
            alt="telegram"
          >
        </a>
        <a
          href="mailto:nstolbova@synergy.ru"
          target="_blank"
          class="auth-form__mail"
        >
          <img
            src="/v2/main/form/mail.svg"
            alt="email"
          >
        </a>
      </div>
    </div>

    <div
      class="auth-form__overlay"
      @click="closeAuthModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RegisterForm from '@/components/v2/auth/RegisterForm.vue';
import LoginForm from '@/components/v2/auth/LoginForm.vue';
import RememberPasswordForm from '@/components/v2/auth/RememberPasswordForm.vue';
import RememberSuccess from '@/components/v2/auth/RememberSuccess.vue';
import redirector from '@/service/redirector';

export default {
  name: 'AuthModal',
  components: {
    RememberSuccess,
    RegisterForm,
    LoginForm,
    RememberPasswordForm,
  },
  props: {
    showForm: {
      type: String,
      default: 'login',
    },
  },
  data() {
    return {
      form: this.showForm,
      error: false,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.keyDownHnd);
    this.$store.dispatch('logout');
  },
  beforeMount() {
    window.removeEventListener('keydown', this.keyDownHnd);
  },
  methods: {
    ...mapActions('modals', {
      closeAuth: 'closeAuth',
    }),
    closeAuthModal() {
      this.$emit('close');
      this.closeAuth();
    },
    shaking() {
      this.error = true;
      window.setTimeout(() => {
        this.error = false;
      }, 500);
    },
    login(user) {
      this.$store.dispatch('login', user);
      this.closeAuthModal();

      if (this.$store.getters.loginEmail) {
        return redirector.toSchool();
      }

      return redirector.toCore();
    },
    register(user) {
      this.closeAuthModal();
      this.$store.commit('loginEmail', user.email);
      this.$gtm.push({ event: 'SignUpForm' });

      if (user.noRedirectToQuiz) {
        this.$store.dispatch('login', user);
      } else {
        window.location = redirector.linkToCore();
      }
    },
    submitRemember({ email }) {
      this.$store.commit('loginEmail', email);
      this.form = 'remember-success';
    },
    keyDownHnd(e) {
      if (e.key === 'Escape') {
        this.closeAuthModal();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.auth-form-container {
  position: absolute;
  left: 50%;
  top: 120px;
  padding: 38px;
  width: min(calc(100% - 16px), 610px);
  box-sizing: border-box;
  animation-name: slideInDown, fadeIn;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  transition: 200ms;
  cursor: default;

  border-radius: 20px;
  border: 2px solid $black;
  background-color: $white-color;
  box-shadow: 4px 4px 0 0 $black-color;

  @include media-down(tablet) {
    top: 76px;
    padding: 38px 6px;
    border-radius: 16px;
    box-shadow: 2px 4px 0 0 $black-color;
  }
}

.auth-form {
  position: fixed;
  overflow-y: auto;
  cursor: pointer;

  animation-name: fadeIn;
  animation-duration: 0.4s;
  animation-fill-mode: both;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2.5px);

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0;
  }

  .tabs {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $black;
    overflow: hidden;

    @include media-down(mobile) {
      border: none;
      gap: 2px;
    }

    button {
      position: relative;
      font-size: 35px;
      font-weight: 500;
      line-height: 100%;
      cursor: pointer;
      color: $black;
      padding: 18px;
      width: 240px;
      border-radius: 20px 20px 0 0;
      border: 2px dashed $black;
      transform: translateY(2px);
      transition: 200ms;
      flex-shrink: 0;
      z-index: 2;

      @include media-down(tablet) {
        font-size: 26px;
        width: 220px;
      }

      @include media-down(mobile) {
        font-size: 18px;
        font-weight: 600;
        line-height: 90%;
        padding: 12px 5px 16px;
        width: 140px;
        border-radius: 16px;
        transform: none;
      }

      &.active {
        border-style: solid;
        cursor: default;
        z-index: 3;
      }

      &:first-of-type {
        margin-right: -2px;

        @include media-down(mobile) {
          margin: 0;
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #fbd277;
          transition: 200ms;
          border-radius: 20px 20px 0 0;
          top: 0;
          left: 0;

          @include media-down(mobile) {
            border-radius: 16px;
          }
        }

        &:not(.active):before {
          left: calc(100% + 2px);

          @include media-down(mobile) {
            left: calc(100% + 6px);
          }
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.auth-form__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 42px;
  transition: transform ease 0.2s;

  &:hover {
    transform: rotate(90deg);
  }
}

.auth-form__soc {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;

  @include media-down(tablet) {
    max-width: 250px;
    margin: 32px auto 0;
  }

  span {
    color: $black;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;

    @include media-down(tablet) {
      font-size: 20px;
      font-weight: 600;
      line-height: 100%;
      max-width: 200px;
    }
  }

  a {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 2px solid $black;
    box-shadow: 2px 2px 0 0 $black;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;

    &:hover {
      box-shadow: none;
    }

    &.auth-form__whatsapp {
      background-color: #40c351;
    }

    &.auth-form__telegram {
      background-color: #2e96ca;
    }

    &.auth-form__mail {
      background-color: #1977f3;
    }
  }
}

::v-deep .fieldset {
  input {
    width: 100%;
    border-radius: 15px;
    border: 2px solid $black;
    height: auto;
    padding: 20px 18px 22px;

    color: $black;
    font-family: 'TildaSans', sans-serif;
    font-size: 20px;
    letter-spacing: 0.4px;

    @include media-down(tablet) {
      padding: 16px 14px 17px;
      font-size: 18px;
      letter-spacing: 0.36px;
    }

    &::placeholder {
      color: rgba($black, 0.9);
    }
  }
}

::v-deep button[type='submit'] {
  width: 100%;
  border-radius: 15px;
  border: 2px solid $black;
  background-color: #b6b6ff;
  padding: 20px 18px 23px;

  color: $black;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Ysabeau', sans-serif;
  font-size: 20px;
  font-weight: 700;

  @include media-down(tablet) {
    font-size: 18px;
    font-weight: 700;
    padding: 16px 10px 19px;
  }
}

.auth-form__remember-success {
  @include media-down(mobile) {
    padding: 14px 14px 30px;

    .auth-form__soc {
      display: none;
    }
  }
}
</style>

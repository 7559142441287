/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'top-arrow': {
    width: 16,
    height: 24,
    viewBox: '0 0 16 24',
    data: '<path pid="0" d="M8 8l3 3M8 8l-3 3m3-3v12" _stroke="#fff" stroke-width="2"/>'
  }
})
